//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-144:_1868,_3776,_3545,_697,_6072,_2548,_3228,_6735;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-144')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-144', "_1868,_3776,_3545,_697,_6072,_2548,_3228,_6735");
        }
      }catch (ex) {
        console.error(ex);
      }